<template>
    <div  v-if="bigData" class="home-container">
       
        <div class="header">{{ stringEmpty(bigData.partyName) }}</div>
        <div class="item-box">
            <div class="item">
                <div class="item-title">CPU</div>
                <div class="item-content">
                    <div class="item-name dis">
                        <span> 总核数：</span>
                        <span>{{ stringEmpty(bigData.cpu_total) }}</span>
                    </div>
                    <div class="item-name dis">
                        <span> 使用率：</span>
                        <span> {{ stringEmpty(bigData.cpu_utilisation) }}</span>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item-title">GPU</div>
                <div class="item-content">
                    <div class="item-name dis">
                        <span> 显存容量：</span>
                        <span>
                            {{ stringEmpty(bigData.GPU_momery_total) }}
                        </span>
                    </div>
                    <div
                        v-if="bigData.GPU_utilisation == null"
                        class="item-name dis"
                    >
                        <span> 显存已用：</span>
                        <span>
                            {{ stringEmpty(bigData.GPU_momery_used) }}
                        </span>
                    </div>
                    <div
                        v-if="bigData.GPU_utilisation == null"
                        class="item-name dis"
                    >
                        <span> 显存使用率：</span>
                        <span>
                            {{ stringEmpty(bigData.GPU_momery_utilisation) }}
                        </span>
                    </div>
                    <div v-else class="item-name dis">
                        <span> 使用率：</span>
                        <span>
                            {{ stringEmpty(bigData.GPU_utilisation) }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item-title">内存</div>
                <div class="item-content">
                    <div class="item-name dis">
                        <span>总内存：</span>
                        <span> {{ stringEmpty(bigData.memory_total) }}</span>
                    </div>
                    <div class="item-name dis">
                        <span> 使用率： </span>
                        <span>
                            {{ stringEmpty(bigData.memory_utilisation) }}</span
                        >
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item-title">磁盘</div>
                <div class="item-content">
                    <div class="item-name dis">
                        <span>容量：</span>
                        <span>
                            {{ stringEmpty(bigData.disk_size_capacity) }}
                        </span>
                    </div>
                    <div class="item-name dis">
                        <span> 已用：</span>
                        <span> {{ stringEmpty(bigData.disk_size_usage) }}</span>
                    </div>
                    <div class="item-name dis">
                        <span> 使用率： </span>
                        <span>{{
                            stringEmpty(bigData.disk_size_utilisation)
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item-title">网络</div>
                <div class="item-content">
                    <div class="item-name dis">
                        <span>流入：</span>
                        <span>
                            {{ stringEmpty(bigData.net_bytes_received) }}
                        </span>
                    </div>
                    <div class="item-name dis">
                        <span> 流出：</span>
                        <span>
                            {{ stringEmpty(bigData.net_bytes_transmitted) }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="item one">
                <div class="item-title">设备状态</div>
                <div class="item-content">
                    <div class="item-name">
                        {{
                            stringEmpty(bigData.status) == 'NORMAL'
                                ? '正常'
                                : ''
                        }}
                    </div>
                </div>
            </div>
            <div class="item one">
                <div class="item-title">总任务</div>
                <div class="item-content">
                    <div class="item-name">
                        {{ bigData.jobNum.all }} <span>个</span>
                    </div>
                </div>
            </div>
            <div class="item one">
                <div class="item-title">已完成任务</div>
                <div class="item-content">
                    <div class="item-name">
                        {{ bigData.jobNum.success }}
                        <span>个</span>
                    </div>
                </div>
            </div>
            <div class="item one">
                <div class="item-title">招募中任务</div>
                <div class="item-content">
                    <div class="item-name">
                        {{ bigData.jobNum.recruit }}
                        <span>个</span>
                    </div>
                </div>
            </div>
            <div class="item one">
                <div class="item-title">图像数据集</div>
                <div class="item-content">
                    <div class="item-name">
                        {{ bigData.dataNum.visionNum }}
                        <span>个</span>
                    </div>
                </div>
            </div>
            <div class="item one">
                <div class="item-title">文本数据集</div>
                <div class="item-content">
                    <div class="item-name">
                        {{ bigData.dataNum.nlpNum }}
                        <span>个</span>
                    </div>
                </div>
            </div>
            <div class="item one">
                <div class="item-title">结构化数据集</div>
                <div class="item-content">
                    <div class="item-name">
                        {{ bigData.dataNum.tabularNum }}
                        <span>个</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">中爱数字眼科联邦协同平台</div>
    </div>
</template>
<script>
import { getList } from '@/api/index.js'
export default {
    name: 'Data',
    data() {
        return {
            bigData: null,
            intervalId: null
         
        }
    },
    created() {
        let defulturl = 'https://fedeye.aierchina.com/?id=1001&hostname=sdaict-flclient-1'
        let param = window.location.href.substring(0, window.location.href.length).split('?')[1] || defulturl.split('?')[1]
        if (param.indexOf('id') === -1 || param.indexOf('hostname') === -1) {
            return
        } else {
            let id = new RegExp('(^|&)' + 'id' + '=([^&]*)(&|$)', 'i')
            let hostname = new RegExp('(^|&)' + 'hostname' + '=([^&]*)(&|$)', 'i')
            let newId = decodeURIComponent(param).match(id)[2]
            let newHostname = decodeURIComponent(param).match(hostname)[2]
            this.fetchData(newId, newHostname) // 先执行一次
            // 设置轮询间隔
            this.intervalId = setInterval(() => {
                setTimeout(() => {
                    this.fetchData(newId, newHostname)
                }, 0)
            }, 10000)
     
        }
    },
    mounted() {
        
    },
    // 在组件销毁时清除轮询
    Unmounted() {
        clearInterval(this.intervalId)
        this.intervalId = null
    },
    beforeDestroy() {
        clearInterval(this.intervalId)
        this.intervalId = null
    },
    methods: {
        stringEmpty(str) {
            return str ? str : ''
        },
        async fetchData(id, hostname) {
            await getList(id, hostname).then((res) => {
                console.log(res)
                if (res.data.code == 200) {
                    this.bigData = res.data.result
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.home-container {
    width: 1280PX;
    height: 720PX;
    background-image: url('../../assets/bg.jpg');
    .header {
        text-align: center;
        color: #fff;
        font-size: 30PX;
        line-height: 200%;
    }
    .item-box {
        margin-top: 30PX;
        margin-left: 38PX;
        padding: 54PX 40PX;
        display: flex;
        flex-wrap: wrap;
        .item {
            box-sizing: border-box;
            width: 174PX;
            height: 182PX;
            // padding: 0 20PX;
            color: #fff;
            font-size: 18PX;
            margin-right: 18PX;
            margin-bottom: 44PX;
            .item-title {
                text-align: center;
                margin-top: 20PX;
                font-size: 16PX;
            }
            .item-content {
                margin-top: 36PX;
                text-align: center;
                .item-name {
                    margin-bottom: 16PX;
                    line-height: 100%;

                    span {
                        font-size: 16PX;
                        // &:first-child {
                        //     width: 30PX;
                        //     text-align: left;
                        // }
                    }
                }
                .dis {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 10PX;
                    span {
                        &:first-child {
                            text-align: right;
                        }
                        &:last-child {
                            flex: 1;
                            flex-shrink: 0;
                        }
                    }
                }
            }
            &:nth-child(2n) {
                box-sizing: border-box;
                width: 174PX;
                height: 182PX;
                // padding: 0 30PX;
                color: #3186db;
                font-size: 18PX;
            }
        }
        .one {
            .item-content {
                font-size: 30PX;
                margin-top: 50PX;
                text-align: center;
                .item-name {
                    margin-bottom: 16PX;
                }
            }
        }
    }
    .footer {
        margin-top: 30PX;
        text-align: center;
        font-size: 24PX;
        color: #0d76e0;
        font-weight: 700;
        margin-left: 30PX;
    }
}
</style>

<template>
    <div class="common_box">
        <div class="main">
            <div class="titile">
                分布式联邦学习平台
            </div>
            <div class="desc">
                原始数据不出域，数据可用不可见。
            </div>
            <div class="img">
                <img src="@/assets/banner.jpg" alt="">
            </div>
        </div>
        <div class="line">
        </div>
        <div class="footer">
            <div class="item">
                <el-card shadow="hover" class="box-card">
                    <div class="header">
                        <div class="img">
                            <img src="@/assets/item.svg" alt="">
                        </div>
                        <div>
                            <div class="number">{{partyNum}}个</div>
                            <div class="typename">联邦方</div>
                        </div>
                    </div>
                </el-card>
                <el-card shadow="hover" class="box-card">
                    <div class="header">
                        <div class="img">
                            <img src="@/assets/task.svg" alt="">
                        </div>
                        <div>
                            <div class="number">{{jobNum}}个</div>
                            <div class="typename">任务</div>
                        </div>
                    </div>
                    
                </el-card>
                <el-card shadow="hover" class="box-card">
                    <div class="header">
                        <div class="img">
                            <img src="@/assets/dataset.svg" alt="">
                        </div>
                        <div>
                            <div class="number">{{metaNum}}个</div>
                            <div class="typename">数据集</div>
                        </div>
                    </div>
                    
                </el-card>
                <el-card shadow="hover" class="box-card">
                    <div class="header">
                        <div class="img">
                            <img src="@/assets/ai.svg" alt="">
                        </div>
                        <div>
                            <div class="number">{{algorithmNum}}个</div>
                            <div class="typename">算法</div>
                        </div>
                    </div>
                    
                </el-card>
                <el-card shadow="hover" class="box-card">
                    <div class="header">
                        <div class="img">
                            <img src="@/assets/model.svg" alt="">
                        </div>

                        <div>
                            <div class="number">{{modelNum}}个</div>
                            <div class="typename">模型</div>
                        </div>
                    </div>
                    
                </el-card>
            </div>
        </div>
    </div>
</template>
<script>
import { loginNum } from '@/api/index.js'
export default {
    data() {
        return {
            jobNum: 0,
            metaNum: 0,
            partyNum: 0,
            algorithmNum: 0,
            modelNum: 0
        }
    },
    created() {
        this.loginNum()
    },
    methods: {
         //登录页接口查询
        async loginNum() {
            await loginNum().then(res=>{
                if(res.data.code == 200) {
                    this.jobNum = res.data.result.jobNum,
                    this.metaNum = res.data.result.metaNum ,
                    this.partyNum =  res.data.result.partyNum
                    this.algorithmNum =  res.data.result.algorithmNum
                    this.modelNum =  res.data.result.modelNum
                 
                }
                
            })
            .catch(err=>{

            })
        }
    }
}
</script>
<style lang="scss" scoped>
// @media screen  and (min-width:1800px){
    .common_box{
        background-color: #f4f6f8;
        height: 100%;
        .main{
            margin: 30px 70px 0;
            border-radius: 10px;
            .titile{
                text-align: left;
                color: #484848;
                font-size: 50px;
                font-weight: 700;
                margin-bottom: 5px;
            }
            .desc{
                // text-indent:2em;
                font-size: 20px;
                line-height: 2;
                margin-bottom: 5px;
            }
            .img{
                text-align: center;
            }
            img{
                width: 100%;
                // height: 500px;
            }
            
        }
        .line{
            border: 1px dashed  #CACACA;
            margin: 20px 60px 20px;
            // margin-bottom: 50px;
        }
        .footer{
            margin: 0px 60px 0px;
            border-radius: 10px;
            .el-card{
                background: transparent;
                border: 2px solid #E8EEF4;
            }
            .heade_titile{
                margin-top: 30px;
                text-align: center;
                font-size: 24px;
                font-weight: 700;
                color: #484848;
            }
            .item{
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                .box-card{
                    margin-right: 20px;
                    width: 220px;
                    ::v-deep .el-card__body{
                        padding: 0 20px;
                    }
                    height:140px;
                    border-radius: 10px;
                    .header{
                        display: flex;
                        // align-items: center;
                        margin-top: 40px;
                        .img{
                            width: 60px;
                            height: 60px;
                            margin-right: 30px;
                            img{
                                width: 100%;
                            }
                        }
                        div{
                            .number{
                                font-size: 24px;
                                font-weight: bold;
                                color: #484848;
                            }
                            .typename{
                                font-size: 18px;
                                font-weight: bold;
                                color: #8E8E8E;
                                margin-top: 20px;
                            }
                        }
                        
                    }
                    .desc-text{
                        margin-top: 28px;
                        text-indent: 2em;
                    }
                }
            }
        }
    }
// }

// @media screen  and (max-width:1799px){
//     .common_box{
//         background-color: #f4f6f8;
//         height: 100%;
//         .main{
//             margin: 70px 70px 0;
//             border-radius: 10px;
//             .titile{
//                 text-align: left;
//                 color: #484848;
//                 font-size: 40px;
//                 font-weight: 700;
//                 margin-bottom: 40px;
//             }
//             .desc{
//                 // text-indent:2em;
//                 line-height: 2;
//                 margin-bottom: 17px;
//             }
//             .img{
//                 text-align: center;
//             }
//             img{
//                 width: 100%;
//             }
            
//         }
//         .line{
//             border: 1px dashed  #CACACA;
//             margin: 0px 60px 50px;
//             // margin-bottom: 50px;
//         }
//         .footer{
//             margin: 0px 60px 0;
//             border-radius: 10px;
//             .el-card{
//                 background: transparent;
//                 border: 2px solid #E8EEF4;
//             }
//             .heade_titile{
//                 text-align: center;
//                 font-size: 24px;
//                 font-weight: 700;
//                 color: #484848;
//             }
//             .item{
//                 margin-top: 40px;
//                 .box-card{
//                     ::v-deep .el-card__body{
//                         padding: 0 30px;
//                     }
//                     height:160px;
//                     border-radius: 10px;
//                     .header{
//                         display: flex;
//                         // align-items: center;
//                         margin-top: 40px;
//                         .img{
//                             width: 60px;
//                             height: 60px;
//                             // width: 100%;
//                             margin-right: 20px;
//                             img{
//                                 width: 100%;
//                             }
                        
//                         }
//                         div{
//                             .number{
//                                 font-size: 24px;
//                                 font-weight: bold;
//                                 color: #484848;
//                             }
//                             .typename{
//                                 font-size: 18px;
//                                 font-weight: bold;
//                                 color: #8E8E8E;
//                                 margin-top: 14px;
//                             }
//                         }
                        
//                     }
//                     .desc-text{
//                         margin-top: 28px;
//                         text-indent: 2em;
//                     }
//                 }
//             }
//         }
//     }   
// }
</style>

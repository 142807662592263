import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/index.scss";
import "@/icons";
import Axios from "axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "normalize.css/normalize.css"; // A modern alternative to CSS resets
import VueClipboard from "vue-clipboard2";
import html2canvas from "html2canvas";
import "@/utils/flexible.js";
import SSO from "@/cas/sso.js";
import { DialogSSL } from "@/utils/useAiErSSL.js";
Vue.prototype.$DialogSSL = DialogSSL; //挂载到vue实例上，可以全局使用了
Vue.config.productionTip = false;
Vue.prototype.$axios = Axios;
Vue.prototype.$html2canvas = html2canvas;
Vue.prototype.$url = "/jeecg-boot/sys/common/viewpic?path=";
import "default-passive-events";

// json start

// json end
Vue.use(ElementUI);
Vue.use(VueClipboard);
const modelDesc = {
  precision: "精确率",
  recall: "召回率",
  accuracy: "准确率",
  f1: "f1值",
  recall_multilabel: "召回率",
  precision_multilabel: "精确率",
  f1_multilabel: "f1值",
  acc_id: "准确率",
  precision_id: "精确率",
  recall_id: "召回率",
  f1_id: "f1值",
  precision_ner: "精确率",
  recall_ner: "召回率",
  f1_ner: "f1值",
  train_acc: "训练集准确率",
  val_acc: "验证集准确率",
  test_acc: "测试集准确率",
};
Vue.prototype.$modelDesc = modelDesc;
// Vue.use(html2canvas)
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
SSO.init(() => {
  main();
});
function main() {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}

import Vue from 'vue'
import SSL from '@/components/AiErSSL.vue'
//通过Vue下的extend方法可以得到组件构造函数//Tip是引入进来的组件对象
const DialogSSLConstructor =  Vue.extend(SSL)

//通过函数创建组件       
export  function DialogSSL(options) {
    //new DialogSSLConstructor的结果得到vue组件对象 //xx.$mount() 放置渲染出来的dom，并将dom挂载到属性上
    const DialogSSLcom = new DialogSSLConstructor({data: options}).$mount()
    //DialogSSLcom是个组件对象，DialogSSLcom.$el是组件对象渲染出来的dom
    document.body.appendChild(DialogSSLcom.$el)
}
import { login } from "@/api/login/index";
import {
  getToken,
  setToken,
  removeToken,
  getUserInfo,
  setUserInfo,
  removeUserInfo,
} from "@/utils/auth";

const state = {
  token: getToken(),
  userInfo: JSON.parse(getUserInfo()),
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERINFO: (state, userinfo) => {
    state.userInfo = userinfo;
  },
};

const actions = {
  // CAS验证登录
  ValidateLogin({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      getAction("/sys/cas/client/validateLogin", userInfo)
        .then((response) => {
          console.log("----cas 登录--------", response);
          if (response.success) {
            const result = response.result;
            const userInfo = result.userInfo;
            commit("SET_TOKEN", data.token);
            commit("SET_USERINFO", data.userInfo);
            setToken(data.token);
            setUserInfo(JSON.stringify(data.userInfo));
            // Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
            // Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
            // Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
            // commit('SET_TOKEN', result.token)
            // commit('SET_INFO', userInfo)
            // commit('SET_NAME', { username: userInfo.username,realname: userInfo.realname, welcome: welcome() })
            // commit('SET_AVATAR', userInfo.avatar)
            resolve(response);
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // set userinfo
  setuserinfo({ commit }, userInfo) {
    return new Promise((resolve) => {
      commit("SET_USERINFO", userInfo);
      setUserInfo(userInfo);
      resolve();
    });
  },
  // user login
  login({ commit }, params) {
    return new Promise((resolve, reject) => {
      login(params)
        .then((response) => {
          const data = response.data.result;
          commit("SET_TOKEN", data.token);
          commit("SET_USERINFO", data.userInfo);
          setToken(data.token);
          setUserInfo(JSON.stringify(data.userInfo));
          resolve(response);
        })
        .catch((res) => {
          reject(res);
        });
    });
  },
  // user logout
  logout({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_USERINFO", "");
      removeToken();
      removeUserInfo();
      resolve();
    });
  },
  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_USERINFO", "");
      removeToken();
      removeUserInfo();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

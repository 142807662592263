<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
        <keep-alive :include="keepAliveComponents">
            <router-view :key="key" />
        </keep-alive>
      <!-- <router-view :key="key" /> -->
    </transition>
  </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    },
    ...mapState({
        keepAliveComponents: state=> state.keepAliveComponents
    })
  }
}
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  /* min-height: calc(100vh - 60px); */
  /* height: 100%; */
  width: 100%;
  position: relative;
  overflow: hidden;
}
.fixed-header+.app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import getters from "./getter";
import user from "./modules/user"
import app from './modules/app'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:'',
    keepAliveComponents: [] // 缓存数组
  },
  mutations: {
    set_token(state, token) {
      state.token = token;
      sessionStorage.token = token;
    },
    del_token(state) {
      state.token = "";
      sessionStorage.removeItem("token");
    },
    keepAlive(state, component) {
        // 防止重复添加
        !state.keepAliveComponents.includes(component) && state.keepAliveComponents.push(component)
    },
    noKeepAlive(state,component) {
        const index = state.keepAliveComponents.indexOf(component)
        index !== -1 && state.keepAliveComponents.splice(index, 1)
    }
  },
  actions: {
  },
  modules: {
    user,
    app
  },
  getters
})
